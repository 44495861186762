import { Dispatch, SetStateAction, useState } from "react";
import {
  Autocomplete,
  Box,
  Button,
  TextField,
  Typography,
} from "@mui/material";

import TRELLO_BID_QUERY from "@sellernote/_shared/src/queries/forwarding/admin/TRELLO_BID_QUERY";
import Modal from "@sellernote/_shared-for-admin/src/components/Modal";

import useSnackbar from "../../../../../../hooks/useSnackbar";

import { PartnerListForAutoComplete } from "../usePartnerNameAutoCompleteOptions";

function RegisterSettlementPartnerModal({
  opensRegisterSettlementPartnerModal,
  setOpensRegisterSettlementPartnerModal,
  bidAccountPayableId,
  etcPartnerListForAutoComplete,
  processSuccessResponse,
}: {
  opensRegisterSettlementPartnerModal: boolean;
  setOpensRegisterSettlementPartnerModal: Dispatch<SetStateAction<boolean>>;
  bidAccountPayableId: number;
  etcPartnerListForAutoComplete: PartnerListForAutoComplete[];
  processSuccessResponse: (message: string) => void;
}) {
  const { handleSnackbarOpen: showSnackbar } = useSnackbar();

  const [partnerId, setPartnerId] = useState(0);

  const { mutate: changeForeignWithdrawalTarget } =
    TRELLO_BID_QUERY.useChangeForeignWithdrawalTarget();

  const handleSaveButtonClick = () => {
    changeForeignWithdrawalTarget(
      {
        withdrawalTargetCompanyId: partnerId,
        isWithdrawalTarget: false,
        pathParams: { bidAccountPayableId },
      },
      {
        onSuccess: () => {
          setOpensRegisterSettlementPartnerModal(false);
          processSuccessResponse("정산 파트너를 등록했습니다.");
        },

        onError: () => showSnackbar("요청에 실패했습니다.", "error"),
      }
    );
  };

  const handleAutocompleteChange = (option: PartnerListForAutoComplete) => {
    if (option.value === "shipper" || !option.value) {
      setPartnerId(0);
      return;
    }

    setPartnerId(option.value);
  };

  return (
    <Modal
      isOpened={opensRegisterSettlementPartnerModal}
      handleClose={() => setOpensRegisterSettlementPartnerModal(false)}
      modalBody={
        <Box display="flex" flexDirection="column" gap={2}>
          <Typography className="sads" variant="h6">
            정산할 회사를 입력하세요.
          </Typography>

          <Autocomplete
            className="sads"
            size="small"
            sx={{ width: 300 }}
            onChange={(_, option) => {
              if (!option) return;

              handleAutocompleteChange(option);
            }}
            isOptionEqualToValue={(option, { value }) => option.value === value}
            options={etcPartnerListForAutoComplete}
            renderInput={(params) => <TextField {...params} label="회사명" />}
          />

          <Button
            variant="contained"
            onClick={handleSaveButtonClick}
            size="medium"
            className="sads"
          >
            저장
          </Button>
        </Box>
      }
    />
  );
}

export default RegisterSettlementPartnerModal;
